import React from 'react';
import { useState } from 'react';
import { useContext, useEffect } from 'react';

import UserContext from '../context/context';

function DotLoop( ) {

  const [state, setValue] = useState( '' );
  const { agent } = useContext( UserContext ) || { agent: {} };
  useEffect( () => {
    if( agent?.email ){
      fetch( `${process.env.GATSBY_API_BASE_URL}/dotloop/state`,
        {
          method: 'POST' ,
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify( {
            id: agent.auth0_id
          } )
        } )
        .then( res => res.json() )
        .then( ( { state } ) => { setValue( state ); } );
    }
  }, [agent?.email] );

  function getToken(){
    window.open( `https://auth.dotloop.com/oauth/authorize?response_type=code&client_id=4ee52704-8b32-4e49-aac0-b8192cd85315&redirect_uri=${process.env.GATSBY_API_BASE_URL}/dotloop&state=${state}` );
  }

  return(
    <>
      <button onClick={getToken}>
        Login
      </button>
    </>
  );
}

export default DotLoop;
